<template>
  <div class="account-information">
    <form @submit.prevent="updateProfile" enctype="multipart/form-data">

      <div class="user-img">
        <input id="imgInp" @change="setAvatar()" ref="avatar" type="file" class="custom-file" name="image">
        <img id="blah" class="img-fluid "
             :src="active_user.avatar ? active_user.avatar : 'https://365psd.com/images/istock/previews/1009/100996291-male-avatar-profile-picture-vector.jpg'"
             alt="">
        <label for="imgInp">change profile picture</label>
      </div>

      <div class="mb-3">
        <label for="name" class="form-label">Your Name</label>
        <input type="text" class="form-control" id="name" v-model="user.full_name">
        <span class="error-msg" v-if="form_errors.hasOwnProperty('full_name')">
          {{ form_errors.full_name[0] }}
        </span>
      </div>
      <div class="mb-3">
        <label for="title" class="form-label">Email</label>
        <input type="text" class="form-control" id="title" readonly v-model="active_user.email">
      </div>
      <div class="mb-3">
        <label for="resume" class="form-label">CV/Resume</label>
        <input type="file" @change="setCV()" ref="cv" class="form-control mb-0" id="resume">
        <div class="form-text"><u><a :href="active_user.cv" target="_blank">Your current cv</a></u></div>
        <span class="error-msg" v-if="form_errors.hasOwnProperty('cv')">
          {{ form_errors.cv[0] }}
        </span>
      </div>
      <div class="mb-3">
        <label for="title" class="form-label">Your Current Password*</label>
        <PasswordField v-model="user.current_password" />
<!--        <input type="password" class="form-control" v-model="user.current_password">-->
        <span class="error-msg" v-if="form_errors.hasOwnProperty('current_password')">
          {{ form_errors.current_password[0] }}
        </span>
      </div>
      <div class="mb-3">
        <div class="d-flex align-items-center">
          <button class="save-btn" type="submit">Save</button>
        </div>

        <div ref="success" class="mt-4 alert alert-success d-none" role="alert"
             @click="$refs.success.classList.add('d-none')">
          Your profile has been saved successfully
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "../../../../axios";
import PasswordField from "@/components/PasswordField.vue";

export default {
  name: "AccountInfo",
  components: {PasswordField},
  data: () => {
    return {
      active_user: {},
      user: {
        full_name: '',
        current_password: ''
      },
      form_errors: []
    }
  },
  methods: {
    updateProfile() {
      let formData = new FormData()
      for (let key in this.user) {
        formData.append(key, this.user[key])
      }
      console.log(formData)
      axios.post('/candidate/', formData).then(response => {
        this.$refs.success.classList.remove('d-none')
        this.$refs.success.className += ' d-block'
        axios.get('/candidate/').then(data => {
          this.$store.commit('UPDATE_USER', data.data)
        })
        this.form_errors = []
      }).catch(error => {
        this.$Progress.fail()
        this.form_errors = error.response.data
      })
    },

    setAvatar() {
      this.user.avatar = this.$refs.avatar.files[0]
      this.active_user.avatar = URL.createObjectURL(this.user.avatar)
    },

    setCV() {
      this.user.cv = this.$refs.cv.files[0]
    }
  },
  mounted() {
    this.user.full_name = this.$store.state.activeUser.full_name
    this.active_user = this.$store.state.activeUser
  },
}
</script>

<style scoped>

</style>