<template>
<div class="delete-page">
  <form action="">
    <div class="mb-4">
      <label for="email" class="form-label">Email Address*</label>
      <div class="input-group">
        <input type="text" id="email" class="form-control"
               placeholder="Your email address" >
      </div>
    </div>
    <div class="mb-4">
      <label for="password" class="form-label">Password*</label>
      <div class="input-group">
        <input :type="showPassword==0 ? 'password': 'text'" id="password" class="form-control"
               placeholder="**********" >
        <span v-show="showPassword==0" class="input-group-text" @click="showPassword=1"><i class="fas fa-eye"></i></span>
        <span v-show="showPassword==1" class="input-group-text" @click="showPassword=0"><i class="fas fa-eye-slash"></i></span>
      </div>
    </div>
    <div class="mb-4">
      <label for="text" class="form-label">Why are you leaving ?</label>
      <div class="input-group">
        <textarea name="" id="" cols="30" rows="5" id="text" class="form-control" placeholder="Tell us why are you leaving?"></textarea>
      </div>
    </div>
    <div class="mb-3">
      <div class="d-flex align-items-center">
        <button class="save-btn red" type="submit">Delete</button>
        <small><img class="img-fluid" src="/assets/dashboard/help-bg.svg" alt="">Your profile information has been saved.</small>
      </div>
    </div>

  </form>
</div>
</template>

<script>
export default {
  name: "DeleteAccount",
  data: () => ({
    showPassword:0
  }),
}
</script>

<style scoped>

</style>