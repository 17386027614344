<template>
  <div class="settings-page d-flex w-100">
    <div class="setting setting-left">
      <a @click.prevent="showPage=index" href="" class="nav-link" :class="[{'active': showPage==index},settingMenu.icon=='far fa-trash-alt' ? 'delete' : '' ]"
         v-for="(settingMenu,index) in settingMenus">
        <i :class="settingMenu.icon"></i>
        {{ settingMenu.name }}
      </a>
    </div>
    <div class="setting setting-right">
      <account-info v-if="showPage==0"/>
      <change-password v-if="showPage==1"/>
<!--      <delete-account v-if="showPage==2"/>-->
    </div>
  </div>
</template>

<script>
import AccountInfo from "./settings/AccountInfo";
import ChangePassword from "./settings/ChangePassword";
import DeleteAccount from "./settings/DeleteAccount";

export default {
  name: "Settings",
  components: {DeleteAccount, ChangePassword, AccountInfo},
  data: () => ({
    showPage: 0,
    settingMenus: [
      {name: 'Account information', icon: 'far fa-user'},
      {name: 'Change password', icon: 'fas fa-lock'},
      // {name: 'Delete account', icon: 'far fa-trash-alt'}
    ],
  })
}
</script>

<style scoped>

</style>