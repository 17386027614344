<template>
  <div class="change-password">

    <div ref="success" class="alert alert-success d-none" role="alert"
         @click="$refs.success.classList.add('d-none')">
      Password has been saved successfully
    </div>

    <form @submit.prevent="changePassword">
      <div class="mb-4">
        <label class="form-label">Current Password</label>
        <PasswordField v-model="pass_form.current_password"/>
        <p class="text-danger" v-if="form_errors.hasOwnProperty('current_password')">
          {{ form_errors.current_password[0] }}
        </p>
      </div>


      <div class="mb-4">
        <label class="form-label">New password</label>
        <PasswordField v-model="pass_form.new_password"/>
        <p class="text-danger" v-if="form_errors.hasOwnProperty('new_password')">
          {{ form_errors.new_password[0] }}
        </p>
      </div>
      <div class="mb-4">
        <label class="form-label">Confirm password</label>
        <PasswordField v-model="pass_form.retype_new_password"/>
        <p class="text-danger" v-if="form_errors.hasOwnProperty('retype_new_password')">
          {{ form_errors.retype_new_password[0] }}
        </p>
      </div>

      <div class="mb-3">
        <div class="d-flex align-items-center">
          <button class="save-btn" type="submit">Save</button>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import axios from "../../../../axios";
import PasswordField from "../../../../components/PasswordField";

export default {
  name: "ChangePassword",
  components: {
    PasswordField
  },

  data: () => ({
    pass_form: {
      current_password: '',
      new_password: '',
      retype_new_password: ''
    },
    form_errors: [],
  }),

  methods: {
    changePassword() {
      axios.post('/change-password/', this.pass_form).then(res => {
        this.form_errors = []
        this.$refs.success.classList.remove('d-none')
        this.$refs.success.className += ' d-block'
        for (let dd in this.pass_form) {
          this.pass_form[dd] = ''
        }
      }).catch(error => {
        this.$Progress.fail()
        this.form_errors = error.response.data
      })
    }
  }
}
</script>

<style scoped>

</style>